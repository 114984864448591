<script setup lang="ts">
import { vElementHover } from '@vueuse/components'

import type { RouteLocationRaw } from '#vue-router'

withDefaults(
  defineProps<{
    title: string
    align?: 'left' | 'right'
    to: string | RouteLocationRaw
    separator?: boolean
  }>(),
  {
    align: 'left',
  },
)

const hover = ref(false)
function onHover(state: boolean) {
  hover.value = state
}
</script>

<template>
  <div
    class="flex flex-col"
    :class="[align === 'left' ? 'items-start' : 'items-end', hover && 'hovered']"
  >
    <div class="w-min">
      <AlLink v-element-hover="onHover" :to="to">
        <p
          class="herolink-text text-4xl font-extrabold uppercase text-white transition-transform ease-out sm:text-6xl"
          :class="[
            align === 'right'
              ? 'text-right [.hovered_&]:translate-x-8'
              : '[.hovered_&]:-translate-x-8',
          ]"
        >
          {{ title }}
        </p>
      </AlLink>
    </div>
    <div
      class="my-2 flex items-center gap-12 self-stretch lg:gap-52"
      :class="{ 'flex-row-reverse': align !== 'left' }"
    >
      <AlLink v-element-hover="onHover" :to="to">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="0.5"
          stroke="currentColor"
          class="h-16 w-16 translate-x-0 text-al-primary transition-transform ease-out"
          :class="align === 'left' ? '[.hovered_&]:translate-x-8' : '[.hovered_&]:-translate-x-8'"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
          />
        </svg>
      </AlLink>
      <hr v-if="separator" class="h-px w-full border-al-primary" />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.herolink-text {
  -webkit-text-stroke: 0.02em rgb(var(--color-primary-DEFAULT));
}
</style>
